<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Répartition des stocks par catégorie de produits">
          <DonutChart
            :data="stockData"
            category-field="category"
            value-field="quantity"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Evolution des stocks au fil du temps">
          <line-with-changing-color
            :data="stockData1"
            value-field="stock"
            date-field="date"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Comparaison des stocks entre différents points de vente">
          <ColumnChart3D
            :data="chartData"
            category-field="store"
            value-field="stock"
            series-name="Stock"
            x-axis-title="Point de vente"
            y-axis-title="Stock"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Identification des produits en rupture de stock">
          <VueGoodTableVue
            :columns="columns"
            :rows="rows"
          /></b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Mouvements de stock">
          <stacked-column-chart
            :data="stockData2"
            category-field="date"
            :series="[
              { seriesName: 'Entrées', valueField: 'inStock' },
              { seriesName: 'Sorties', valueField: 'outStock' },
            ]"
            x-axis-title="Date"
            y-axis-title="Quantité"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Prévisions de stock en fonction des ventes passées et des tendances du marché">
          <LineWithChangingColor
            :data="salesData"
            value-field="sales"
            date-field="date"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Analyse de la rentabilité des produits en fonction de leur niveau de stock et de leur taux de rotation">
          <StackedColumnChart
            :data="chartData2"
            category-field="category"
            :series="chartSeries"
            x-axis-title="Produits"
            y-axis-title="Valeur"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import DonutChart from '@/views/m-components/charts/DonutChart.vue'
import LineWithChangingColor from '@/views/m-components/charts/LineWithChangingColor.vue'
import ColumnChart3D from '@/views/m-components/charts/ColumnChart3D.vue'
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'

export default {
  name: 'MainVue',
  components: {
    StackedColumnChart,
    VueGoodTableVue,
    ColumnChart3D,
    LineWithChangingColor,
    DonutChart,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      stockData: [
        { category: 'Produit A', quantity: 100 },
        { category: 'Produit B', quantity: 150 },
        { category: 'Produit C', quantity: 75 },
        { category: 'Produit D', quantity: 200 },
        { category: 'Produit E', quantity: 50 },
      ],
      stockData1: [
        { date: '2022-01-01', stock: 500 },
        { date: '2022-02-01', stock: 450 },
        { date: '2022-03-01', stock: 400 },
        { date: '2022-04-01', stock: 350 },
        { date: '2022-05-01', stock: 300 },
        { date: '2022-06-01', stock: 2050 },
        { date: '2022-07-01', stock: 1300 },
        { date: '2022-08-01', stock: 1050 },
        { date: '2022-09-01', stock: 500 },
        { date: '2022-10-01', stock: 200 },
        { date: '2022-11-01', stock: 100 },
        { date: '2022-12-01', stock: 50 },
      ],
      chartData: [
        { store: 'Point de vente 1', stock: 100 },
        { store: 'Point de vente 2', stock: 80 },
        { store: 'Point de vente 3', stock: 120 },
        { store: 'Point de vente 4', stock: 90 },
      ],
      columns: [
        {
          label: 'ID Produit',
          field: 'id_produit',
        },
        {
          label: 'Nom du produit',
          field: 'nom_produit',
        },
        {
          label: 'Quantité en stock',
          field: 'quantite_stock',
        },
      ],
      rows: [
        {
          id_produit: 1,
          nom_produit: 'Produit A',
          quantite_stock: 0,
        },
        {
          id_produit: 2,
          nom_produit: 'Produit B',
          quantite_stock: 0,
        },
        {
          id_produit: 3,
          nom_produit: 'Produit C',
          quantite_stock: 0,
        },
        {
          id_produit: 4,
          nom_produit: 'Produit D',
          quantite_stock: 0,
        },
      ],
      stockData2: [
        { date: '2022-01-01', inStock: 100, outStock: 50 },
        { date: '2022-01-02', inStock: 80, outStock: 70 },
        { date: '2022-01-03', inStock: 120, outStock: 90 },
        { date: '2022-01-04', inStock: 70, outStock: 60 },
        { date: '2022-01-05', inStock: 90, outStock: 100 },
        { date: '2022-01-06', inStock: 110, outStock: 80 },
        { date: '2022-01-07', inStock: 70, outStock: 60 },
      ],
      salesData: [
        { date: new Date('2021-01-01'), sales: 100 },
        { date: new Date('2021-02-01'), sales: 120 },
        { date: new Date('2021-03-01'), sales: 150 },
        { date: new Date('2021-04-01'), sales: 170 },
        { date: new Date('2021-05-01'), sales: 200 },
        { date: new Date('2021-06-01'), sales: 220 },
        { date: new Date('2021-07-01'), sales: 240 },
        { date: new Date('2021-08-01'), sales: 260 },
        { date: new Date('2021-09-01'), sales: 280 },
        { date: new Date('2021-10-01'), sales: 300 },
        { date: new Date('2021-11-01'), sales: 320 },
        { date: new Date('2021-12-01'), sales: 340 },
      ],
      chartData2: [
        {
          category: 'Produit 1',
          stock: 300,
          rotation: 10,
          profit: 1500,
        },
        {
          category: 'Produit 2',
          stock: 500,
          rotation: 15,
          profit: 2000,
        },
        {
          category: 'Produit 3',
          stock: 200,
          rotation: 5,
          profit: 800,
        },
        {
          category: 'Produit 4',
          stock: 100,
          rotation: 12,
          profit: 1200,
        },
      ],
      chartSeries: [
        {
          seriesName: 'Stock',
          valueField: 'stock',
          color: '#00bfff',
        },
        {
          seriesName: 'Rotation',
          valueField: 'rotation',
          color: '#008000',
        },
        {
          seriesName: 'Profit',
          valueField: 'profit',
          color: '#ff8c00',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
